import React from "react"

import { SEO } from "components"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
  </>
)

export default NotFoundPage
